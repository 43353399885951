// source: projects/erp/service/api/v1/model/property_query.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global = (function() { return this || window || global || self || Function('return this')(); }).call(null);

var google_protobuf_wrappers_pb = require('google-protobuf/google/protobuf/wrappers_pb.js');
goog.object.extend(proto, google_protobuf_wrappers_pb);
var projects_erp_service_api_v1_model_money_pb = require('../money_pb.js');
goog.object.extend(proto, projects_erp_service_api_v1_model_money_pb);
var projects_erp_service_api_v1_model_list_of_strings_pb = require('../list_of_strings_pb.js');
goog.object.extend(proto, projects_erp_service_api_v1_model_list_of_strings_pb);
var projects_erp_service_api_v1_model_unit_pb = require('../unit_pb.js');
goog.object.extend(proto, projects_erp_service_api_v1_model_unit_pb);
var google_protobuf_timestamp_pb = require('google-protobuf/google/protobuf/timestamp_pb.js');
goog.object.extend(proto, google_protobuf_timestamp_pb);
goog.exportSymbol('proto.qowner.erp.api.v1.model.PropertyQuery', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.qowner.erp.api.v1.model.PropertyQuery = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.qowner.erp.api.v1.model.PropertyQuery.repeatedFields_, null);
};
goog.inherits(proto.qowner.erp.api.v1.model.PropertyQuery, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.qowner.erp.api.v1.model.PropertyQuery.displayName = 'proto.qowner.erp.api.v1.model.PropertyQuery';
}

/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.qowner.erp.api.v1.model.PropertyQuery.repeatedFields_ = [6,7,8,9];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.qowner.erp.api.v1.model.PropertyQuery.prototype.toObject = function(opt_includeInstance) {
  return proto.qowner.erp.api.v1.model.PropertyQuery.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.qowner.erp.api.v1.model.PropertyQuery} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.qowner.erp.api.v1.model.PropertyQuery.toObject = function(includeInstance, msg) {
  var f, obj = {
    name: jspb.Message.getFieldWithDefault(msg, 1, ""),
    id: jspb.Message.getFieldWithDefault(msg, 2, 0),
    displayName: jspb.Message.getFieldWithDefault(msg, 3, ""),
    customerName: jspb.Message.getFieldWithDefault(msg, 4, ""),
    countryName: jspb.Message.getFieldWithDefault(msg, 5, ""),
    developerNamesList: (f = jspb.Message.getRepeatedField(msg, 6)) == null ? undefined : f,
    typeNamesList: (f = jspb.Message.getRepeatedField(msg, 7)) == null ? undefined : f,
    statusNamesList: (f = jspb.Message.getRepeatedField(msg, 8)) == null ? undefined : f,
    locationNamesList: (f = jspb.Message.getRepeatedField(msg, 9)) == null ? undefined : f,
    amountRange: (f = msg.getAmountRange()) && projects_erp_service_api_v1_model_money_pb.MoneyRange.toObject(includeInstance, f),
    rentRange: (f = msg.getRentRange()) && projects_erp_service_api_v1_model_money_pb.MoneyRange.toObject(includeInstance, f),
    valuesMap: (f = msg.getValuesMap()) ? f.toObject(includeInstance, proto.qowner.erp.api.v1.model.ListOfStrings.toObject) : [],
    unitRangesMap: (f = msg.getUnitRangesMap()) ? f.toObject(includeInstance, proto.qowner.erp.api.v1.model.UnitRange.toObject) : [],
    amenitiesMap: (f = msg.getAmenitiesMap()) ? f.toObject(includeInstance, proto.qowner.erp.api.v1.model.ListOfStrings.toObject) : [],
    isActive: jspb.Message.getBooleanFieldWithDefault(msg, 15, false),
    projectCount: jspb.Message.getFieldWithDefault(msg, 16, 0),
    objectCount: jspb.Message.getFieldWithDefault(msg, 17, 0),
    propertyName: (f = msg.getPropertyName()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
    description: (f = msg.getDescription()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
    createdAt: (f = msg.getCreatedAt()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.qowner.erp.api.v1.model.PropertyQuery}
 */
proto.qowner.erp.api.v1.model.PropertyQuery.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.qowner.erp.api.v1.model.PropertyQuery;
  return proto.qowner.erp.api.v1.model.PropertyQuery.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.qowner.erp.api.v1.model.PropertyQuery} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.qowner.erp.api.v1.model.PropertyQuery}
 */
proto.qowner.erp.api.v1.model.PropertyQuery.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setDisplayName(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setCustomerName(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setCountryName(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.addDeveloperNames(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.addTypeNames(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.addStatusNames(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.addLocationNames(value);
      break;
    case 10:
      var value = new projects_erp_service_api_v1_model_money_pb.MoneyRange;
      reader.readMessage(value,projects_erp_service_api_v1_model_money_pb.MoneyRange.deserializeBinaryFromReader);
      msg.setAmountRange(value);
      break;
    case 11:
      var value = new projects_erp_service_api_v1_model_money_pb.MoneyRange;
      reader.readMessage(value,projects_erp_service_api_v1_model_money_pb.MoneyRange.deserializeBinaryFromReader);
      msg.setRentRange(value);
      break;
    case 12:
      var value = msg.getValuesMap();
      reader.readMessage(value, function(message, reader) {
        jspb.Map.deserializeBinary(message, reader, jspb.BinaryReader.prototype.readString, jspb.BinaryReader.prototype.readMessage, proto.qowner.erp.api.v1.model.ListOfStrings.deserializeBinaryFromReader, "", new proto.qowner.erp.api.v1.model.ListOfStrings());
         });
      break;
    case 13:
      var value = msg.getUnitRangesMap();
      reader.readMessage(value, function(message, reader) {
        jspb.Map.deserializeBinary(message, reader, jspb.BinaryReader.prototype.readString, jspb.BinaryReader.prototype.readMessage, proto.qowner.erp.api.v1.model.UnitRange.deserializeBinaryFromReader, "", new proto.qowner.erp.api.v1.model.UnitRange());
         });
      break;
    case 14:
      var value = msg.getAmenitiesMap();
      reader.readMessage(value, function(message, reader) {
        jspb.Map.deserializeBinary(message, reader, jspb.BinaryReader.prototype.readString, jspb.BinaryReader.prototype.readMessage, proto.qowner.erp.api.v1.model.ListOfStrings.deserializeBinaryFromReader, "", new proto.qowner.erp.api.v1.model.ListOfStrings());
         });
      break;
    case 15:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsActive(value);
      break;
    case 16:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setProjectCount(value);
      break;
    case 17:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setObjectCount(value);
      break;
    case 18:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setPropertyName(value);
      break;
    case 19:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setDescription(value);
      break;
    case 20:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setCreatedAt(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.qowner.erp.api.v1.model.PropertyQuery.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.qowner.erp.api.v1.model.PropertyQuery.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.qowner.erp.api.v1.model.PropertyQuery} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.qowner.erp.api.v1.model.PropertyQuery.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getId();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
  f = message.getDisplayName();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getCustomerName();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getCountryName();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getDeveloperNamesList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      6,
      f
    );
  }
  f = message.getTypeNamesList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      7,
      f
    );
  }
  f = message.getStatusNamesList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      8,
      f
    );
  }
  f = message.getLocationNamesList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      9,
      f
    );
  }
  f = message.getAmountRange();
  if (f != null) {
    writer.writeMessage(
      10,
      f,
      projects_erp_service_api_v1_model_money_pb.MoneyRange.serializeBinaryToWriter
    );
  }
  f = message.getRentRange();
  if (f != null) {
    writer.writeMessage(
      11,
      f,
      projects_erp_service_api_v1_model_money_pb.MoneyRange.serializeBinaryToWriter
    );
  }
  f = message.getValuesMap(true);
  if (f && f.getLength() > 0) {
    f.serializeBinary(12, writer, jspb.BinaryWriter.prototype.writeString, jspb.BinaryWriter.prototype.writeMessage, proto.qowner.erp.api.v1.model.ListOfStrings.serializeBinaryToWriter);
  }
  f = message.getUnitRangesMap(true);
  if (f && f.getLength() > 0) {
    f.serializeBinary(13, writer, jspb.BinaryWriter.prototype.writeString, jspb.BinaryWriter.prototype.writeMessage, proto.qowner.erp.api.v1.model.UnitRange.serializeBinaryToWriter);
  }
  f = message.getAmenitiesMap(true);
  if (f && f.getLength() > 0) {
    f.serializeBinary(14, writer, jspb.BinaryWriter.prototype.writeString, jspb.BinaryWriter.prototype.writeMessage, proto.qowner.erp.api.v1.model.ListOfStrings.serializeBinaryToWriter);
  }
  f = message.getIsActive();
  if (f) {
    writer.writeBool(
      15,
      f
    );
  }
  f = message.getProjectCount();
  if (f !== 0) {
    writer.writeUint32(
      16,
      f
    );
  }
  f = message.getObjectCount();
  if (f !== 0) {
    writer.writeUint32(
      17,
      f
    );
  }
  f = message.getPropertyName();
  if (f != null) {
    writer.writeMessage(
      18,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getDescription();
  if (f != null) {
    writer.writeMessage(
      19,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getCreatedAt();
  if (f != null) {
    writer.writeMessage(
      20,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
};


/**
 * optional string name = 1;
 * @return {string}
 */
proto.qowner.erp.api.v1.model.PropertyQuery.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.qowner.erp.api.v1.model.PropertyQuery} returns this
 */
proto.qowner.erp.api.v1.model.PropertyQuery.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional int32 id = 2;
 * @return {number}
 */
proto.qowner.erp.api.v1.model.PropertyQuery.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.qowner.erp.api.v1.model.PropertyQuery} returns this
 */
proto.qowner.erp.api.v1.model.PropertyQuery.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional string display_name = 3;
 * @return {string}
 */
proto.qowner.erp.api.v1.model.PropertyQuery.prototype.getDisplayName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.qowner.erp.api.v1.model.PropertyQuery} returns this
 */
proto.qowner.erp.api.v1.model.PropertyQuery.prototype.setDisplayName = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string customer_name = 4;
 * @return {string}
 */
proto.qowner.erp.api.v1.model.PropertyQuery.prototype.getCustomerName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.qowner.erp.api.v1.model.PropertyQuery} returns this
 */
proto.qowner.erp.api.v1.model.PropertyQuery.prototype.setCustomerName = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string country_name = 5;
 * @return {string}
 */
proto.qowner.erp.api.v1.model.PropertyQuery.prototype.getCountryName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.qowner.erp.api.v1.model.PropertyQuery} returns this
 */
proto.qowner.erp.api.v1.model.PropertyQuery.prototype.setCountryName = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * repeated string developer_names = 6;
 * @return {!Array<string>}
 */
proto.qowner.erp.api.v1.model.PropertyQuery.prototype.getDeveloperNamesList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 6));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.qowner.erp.api.v1.model.PropertyQuery} returns this
 */
proto.qowner.erp.api.v1.model.PropertyQuery.prototype.setDeveloperNamesList = function(value) {
  return jspb.Message.setField(this, 6, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.qowner.erp.api.v1.model.PropertyQuery} returns this
 */
proto.qowner.erp.api.v1.model.PropertyQuery.prototype.addDeveloperNames = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 6, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.qowner.erp.api.v1.model.PropertyQuery} returns this
 */
proto.qowner.erp.api.v1.model.PropertyQuery.prototype.clearDeveloperNamesList = function() {
  return this.setDeveloperNamesList([]);
};


/**
 * repeated string type_names = 7;
 * @return {!Array<string>}
 */
proto.qowner.erp.api.v1.model.PropertyQuery.prototype.getTypeNamesList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 7));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.qowner.erp.api.v1.model.PropertyQuery} returns this
 */
proto.qowner.erp.api.v1.model.PropertyQuery.prototype.setTypeNamesList = function(value) {
  return jspb.Message.setField(this, 7, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.qowner.erp.api.v1.model.PropertyQuery} returns this
 */
proto.qowner.erp.api.v1.model.PropertyQuery.prototype.addTypeNames = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 7, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.qowner.erp.api.v1.model.PropertyQuery} returns this
 */
proto.qowner.erp.api.v1.model.PropertyQuery.prototype.clearTypeNamesList = function() {
  return this.setTypeNamesList([]);
};


/**
 * repeated string status_names = 8;
 * @return {!Array<string>}
 */
proto.qowner.erp.api.v1.model.PropertyQuery.prototype.getStatusNamesList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 8));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.qowner.erp.api.v1.model.PropertyQuery} returns this
 */
proto.qowner.erp.api.v1.model.PropertyQuery.prototype.setStatusNamesList = function(value) {
  return jspb.Message.setField(this, 8, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.qowner.erp.api.v1.model.PropertyQuery} returns this
 */
proto.qowner.erp.api.v1.model.PropertyQuery.prototype.addStatusNames = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 8, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.qowner.erp.api.v1.model.PropertyQuery} returns this
 */
proto.qowner.erp.api.v1.model.PropertyQuery.prototype.clearStatusNamesList = function() {
  return this.setStatusNamesList([]);
};


/**
 * repeated string location_names = 9;
 * @return {!Array<string>}
 */
proto.qowner.erp.api.v1.model.PropertyQuery.prototype.getLocationNamesList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 9));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.qowner.erp.api.v1.model.PropertyQuery} returns this
 */
proto.qowner.erp.api.v1.model.PropertyQuery.prototype.setLocationNamesList = function(value) {
  return jspb.Message.setField(this, 9, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.qowner.erp.api.v1.model.PropertyQuery} returns this
 */
proto.qowner.erp.api.v1.model.PropertyQuery.prototype.addLocationNames = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 9, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.qowner.erp.api.v1.model.PropertyQuery} returns this
 */
proto.qowner.erp.api.v1.model.PropertyQuery.prototype.clearLocationNamesList = function() {
  return this.setLocationNamesList([]);
};


/**
 * optional MoneyRange amount_range = 10;
 * @return {?proto.qowner.erp.api.v1.model.MoneyRange}
 */
proto.qowner.erp.api.v1.model.PropertyQuery.prototype.getAmountRange = function() {
  return /** @type{?proto.qowner.erp.api.v1.model.MoneyRange} */ (
    jspb.Message.getWrapperField(this, projects_erp_service_api_v1_model_money_pb.MoneyRange, 10));
};


/**
 * @param {?proto.qowner.erp.api.v1.model.MoneyRange|undefined} value
 * @return {!proto.qowner.erp.api.v1.model.PropertyQuery} returns this
*/
proto.qowner.erp.api.v1.model.PropertyQuery.prototype.setAmountRange = function(value) {
  return jspb.Message.setWrapperField(this, 10, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.qowner.erp.api.v1.model.PropertyQuery} returns this
 */
proto.qowner.erp.api.v1.model.PropertyQuery.prototype.clearAmountRange = function() {
  return this.setAmountRange(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.qowner.erp.api.v1.model.PropertyQuery.prototype.hasAmountRange = function() {
  return jspb.Message.getField(this, 10) != null;
};


/**
 * optional MoneyRange rent_range = 11;
 * @return {?proto.qowner.erp.api.v1.model.MoneyRange}
 */
proto.qowner.erp.api.v1.model.PropertyQuery.prototype.getRentRange = function() {
  return /** @type{?proto.qowner.erp.api.v1.model.MoneyRange} */ (
    jspb.Message.getWrapperField(this, projects_erp_service_api_v1_model_money_pb.MoneyRange, 11));
};


/**
 * @param {?proto.qowner.erp.api.v1.model.MoneyRange|undefined} value
 * @return {!proto.qowner.erp.api.v1.model.PropertyQuery} returns this
*/
proto.qowner.erp.api.v1.model.PropertyQuery.prototype.setRentRange = function(value) {
  return jspb.Message.setWrapperField(this, 11, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.qowner.erp.api.v1.model.PropertyQuery} returns this
 */
proto.qowner.erp.api.v1.model.PropertyQuery.prototype.clearRentRange = function() {
  return this.setRentRange(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.qowner.erp.api.v1.model.PropertyQuery.prototype.hasRentRange = function() {
  return jspb.Message.getField(this, 11) != null;
};


/**
 * map<string, ListOfStrings> values = 12;
 * @param {boolean=} opt_noLazyCreate Do not create the map if
 * empty, instead returning `undefined`
 * @return {!jspb.Map<string,!proto.qowner.erp.api.v1.model.ListOfStrings>}
 */
proto.qowner.erp.api.v1.model.PropertyQuery.prototype.getValuesMap = function(opt_noLazyCreate) {
  return /** @type {!jspb.Map<string,!proto.qowner.erp.api.v1.model.ListOfStrings>} */ (
      jspb.Message.getMapField(this, 12, opt_noLazyCreate,
      proto.qowner.erp.api.v1.model.ListOfStrings));
};


/**
 * Clears values from the map. The map will be non-null.
 * @return {!proto.qowner.erp.api.v1.model.PropertyQuery} returns this
 */
proto.qowner.erp.api.v1.model.PropertyQuery.prototype.clearValuesMap = function() {
  this.getValuesMap().clear();
  return this;};


/**
 * map<string, UnitRange> unit_ranges = 13;
 * @param {boolean=} opt_noLazyCreate Do not create the map if
 * empty, instead returning `undefined`
 * @return {!jspb.Map<string,!proto.qowner.erp.api.v1.model.UnitRange>}
 */
proto.qowner.erp.api.v1.model.PropertyQuery.prototype.getUnitRangesMap = function(opt_noLazyCreate) {
  return /** @type {!jspb.Map<string,!proto.qowner.erp.api.v1.model.UnitRange>} */ (
      jspb.Message.getMapField(this, 13, opt_noLazyCreate,
      proto.qowner.erp.api.v1.model.UnitRange));
};


/**
 * Clears values from the map. The map will be non-null.
 * @return {!proto.qowner.erp.api.v1.model.PropertyQuery} returns this
 */
proto.qowner.erp.api.v1.model.PropertyQuery.prototype.clearUnitRangesMap = function() {
  this.getUnitRangesMap().clear();
  return this;};


/**
 * map<string, ListOfStrings> amenities = 14;
 * @param {boolean=} opt_noLazyCreate Do not create the map if
 * empty, instead returning `undefined`
 * @return {!jspb.Map<string,!proto.qowner.erp.api.v1.model.ListOfStrings>}
 */
proto.qowner.erp.api.v1.model.PropertyQuery.prototype.getAmenitiesMap = function(opt_noLazyCreate) {
  return /** @type {!jspb.Map<string,!proto.qowner.erp.api.v1.model.ListOfStrings>} */ (
      jspb.Message.getMapField(this, 14, opt_noLazyCreate,
      proto.qowner.erp.api.v1.model.ListOfStrings));
};


/**
 * Clears values from the map. The map will be non-null.
 * @return {!proto.qowner.erp.api.v1.model.PropertyQuery} returns this
 */
proto.qowner.erp.api.v1.model.PropertyQuery.prototype.clearAmenitiesMap = function() {
  this.getAmenitiesMap().clear();
  return this;};


/**
 * optional bool is_active = 15;
 * @return {boolean}
 */
proto.qowner.erp.api.v1.model.PropertyQuery.prototype.getIsActive = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 15, false));
};


/**
 * @param {boolean} value
 * @return {!proto.qowner.erp.api.v1.model.PropertyQuery} returns this
 */
proto.qowner.erp.api.v1.model.PropertyQuery.prototype.setIsActive = function(value) {
  return jspb.Message.setProto3BooleanField(this, 15, value);
};


/**
 * optional uint32 project_count = 16;
 * @return {number}
 */
proto.qowner.erp.api.v1.model.PropertyQuery.prototype.getProjectCount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 16, 0));
};


/**
 * @param {number} value
 * @return {!proto.qowner.erp.api.v1.model.PropertyQuery} returns this
 */
proto.qowner.erp.api.v1.model.PropertyQuery.prototype.setProjectCount = function(value) {
  return jspb.Message.setProto3IntField(this, 16, value);
};


/**
 * optional uint32 object_count = 17;
 * @return {number}
 */
proto.qowner.erp.api.v1.model.PropertyQuery.prototype.getObjectCount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 17, 0));
};


/**
 * @param {number} value
 * @return {!proto.qowner.erp.api.v1.model.PropertyQuery} returns this
 */
proto.qowner.erp.api.v1.model.PropertyQuery.prototype.setObjectCount = function(value) {
  return jspb.Message.setProto3IntField(this, 17, value);
};


/**
 * optional google.protobuf.StringValue property_name = 18;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.qowner.erp.api.v1.model.PropertyQuery.prototype.getPropertyName = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 18));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.qowner.erp.api.v1.model.PropertyQuery} returns this
*/
proto.qowner.erp.api.v1.model.PropertyQuery.prototype.setPropertyName = function(value) {
  return jspb.Message.setWrapperField(this, 18, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.qowner.erp.api.v1.model.PropertyQuery} returns this
 */
proto.qowner.erp.api.v1.model.PropertyQuery.prototype.clearPropertyName = function() {
  return this.setPropertyName(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.qowner.erp.api.v1.model.PropertyQuery.prototype.hasPropertyName = function() {
  return jspb.Message.getField(this, 18) != null;
};


/**
 * optional google.protobuf.StringValue description = 19;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.qowner.erp.api.v1.model.PropertyQuery.prototype.getDescription = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 19));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.qowner.erp.api.v1.model.PropertyQuery} returns this
*/
proto.qowner.erp.api.v1.model.PropertyQuery.prototype.setDescription = function(value) {
  return jspb.Message.setWrapperField(this, 19, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.qowner.erp.api.v1.model.PropertyQuery} returns this
 */
proto.qowner.erp.api.v1.model.PropertyQuery.prototype.clearDescription = function() {
  return this.setDescription(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.qowner.erp.api.v1.model.PropertyQuery.prototype.hasDescription = function() {
  return jspb.Message.getField(this, 19) != null;
};


/**
 * optional google.protobuf.Timestamp created_at = 20;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.qowner.erp.api.v1.model.PropertyQuery.prototype.getCreatedAt = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 20));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.qowner.erp.api.v1.model.PropertyQuery} returns this
*/
proto.qowner.erp.api.v1.model.PropertyQuery.prototype.setCreatedAt = function(value) {
  return jspb.Message.setWrapperField(this, 20, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.qowner.erp.api.v1.model.PropertyQuery} returns this
 */
proto.qowner.erp.api.v1.model.PropertyQuery.prototype.clearCreatedAt = function() {
  return this.setCreatedAt(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.qowner.erp.api.v1.model.PropertyQuery.prototype.hasCreatedAt = function() {
  return jspb.Message.getField(this, 20) != null;
};


goog.object.extend(exports, proto.qowner.erp.api.v1.model);
