import wrappers from 'google-protobuf/google/protobuf/wrappers_pb';
import { ApiUrl, getMetadata } from '../index'
import { City } from './city_pb'
import { Country } from './country_pb'
import { State } from './state_pb'
import { Quarter } from './quarter_pb'
import { Settlement } from './settlement_pb'
import { District } from './district_pb'
import {
  GetCityRequest,
  ListCitiesRequest,
  ApplyCityRequest
} from './cities_pb'
import {
  GetCountryRequest,
  ListCountriesRequest,
  ApplyCountryRequest
} from './countries_pb'
import {
  GetStateRequest,
  ListStatesRequest,
  ApplyStateRequest
} from './states_pb'
import {
  GetQuarterRequest,
  ListQuartersRequest,
  ApplyQuarterRequest
} from './quarters_pb'
import {
  GetSettlementRequest,
  ListSettlementsRequest,
  ApplySettlementRequest,
} from './settlements_pb'
import {
  GetDistrictRequest,
  ListDistrictsRequest,
  ApplyDistrictRequest
} from './districts_pb'
import { CitiesClient } from './cities_grpc_web_pb'
import { CountriesClient } from './countries_grpc_web_pb'
import { StatesClient } from './states_grpc_web_pb'
import { QuartersClient } from './quarters_grpc_web_pb'
import { SettlementsClient } from './settlements_grpc_web_pb'
import { DistrictsClient } from './districts_grpc_web_pb'

let citiesClient = new CitiesClient(ApiUrl, null, null);
let countriesClient = new CountriesClient(ApiUrl, null, null);
let statesClient = new StatesClient(ApiUrl, null, null);
let quartersClient = new QuartersClient(ApiUrl, null, null);
let settlementsClient = new SettlementsClient(ApiUrl, null, null);
let districtsClient = new DistrictsClient(ApiUrl, null, null);

const district2Model = (instance) => {
  let result = new District()
  if (instance.name)
    result.setName(instance.name)
  result.setCode(instance.code)
  result.setCountryName(instance.countryName)
  result.setDisplayName(instance.displayName)
  result.setDescription(instance.description)
  if (instance?.bazarakiCode) {
    const wrapped = new wrappers.StringValue()
    wrapped.setValue(instance.bazarakiCode)
    result.setBazarakiCode(wrapped)
  }
  return result
}

const settlement2Model = (instance) => {
  let result = new Settlement()
  if (instance.name)
    result.setName(instance.name)
  result.setCode(instance.code)
  result.setDisplayName(instance.displayName)
  result.setCountryName(instance.countryName)
  result.setDistrictName(instance.districtName)
  result.setDescription(instance.description)
  if (instance?.bazarakiCode) {
    const wrapped = new wrappers.StringValue()
    wrapped.setValue(instance.bazarakiCode)
    result.setBazarakiCode(wrapped)
  }
  return result
}

const quarter2Model = (instance) => {
  let result = new Quarter()
  if (instance.name)
    result.setName(instance.name)
  result.setCode(instance.code)
  result.setDisplayName(instance.displayName)
  result.setCountryName(instance.countryName)
  result.setDistrictName(instance.districtName)
  result.setSettlementName(instance.settlementName)
  result.setDescription(instance.description)
  if (instance?.bazarakiCode) {
    const wrapped = new wrappers.StringValue()
    wrapped.setValue(instance.bazarakiCode)
    result.setBazarakiCode(wrapped)
  }
  return result
}

const state2Model = (instance) => {
  let result = new State()
  if (instance.name)
    result.setName(instance.name)
  result.setCode(instance.code)
  result.setDisplayName(instance.displayName)
  result.setCountryName(instance.countryName)
  return result
}

const country2Model = (instance) => {
  let result = new Country()
  if (instance.name)
    result.setName(instance.name)
  result.setCode(instance.code)
  result.setDisplayName(instance.displayName)
  return result
}

const city2Model = (instance) => {
  let result = new City()
  if (instance.name)
    result.setName(instance.name)
  result.setCode(instance.code)
  result.setDisplayName(instance.displayName)
  result.setCountryName(instance.countryName)
  result.setStateName(instance.stateName)
  return result
}

const API = {
  listCountries: () => {
    const request = new ListCountriesRequest();
    return new Promise((resolve) => {
      countriesClient.listCountries(request, getMetadata(), (err, response) => {
        if (response == null) {
          console.log(err)
        } else {
          resolve(response.toObject().countriesList)
        }
      });
    });
  },
  getCountry: (name) => {
    const request = new GetCountryRequest()
    request.setName(name)
    return new Promise((resolve) => {
      countriesClient.getCountry(request, getMetadata(), (err, response) => {
        if (response == null) {
          console.log(err)
        } else {
          resolve(response.toObject())
        }
      });
    });
  },
  applyCountry: (country) => {
    const coun = country2Model(country)
    const request = new ApplyCountryRequest();
    request.setName(country.name)
    request.setCountry(coun)
    return new Promise((resolve) => {
      countriesClient.applyCountry(request, getMetadata(), (err, response) => {
        if (response == null) {
          console.log(err)
        } else {
          resolve(response.toObject())
        }
      })
    })
  },
  listStates: () => {
    const request = new ListStatesRequest();
    return new Promise((resolve) => {
      statesClient.listStates(request, getMetadata(), (err, response) => {
        if (response == null) {
          console.log(err)
        } else {
          resolve(response.toObject().statesList)
        }
      });
    });
  },
  getState: (name) => {
    const request = new GetStateRequest()
    request.setName(name)
    return new Promise((resolve) => {
      statesClient.getState(request, getMetadata(), (err, response) => {
        if (response == null) {
          console.log(err)
        } else {
          resolve(response.toObject())
        }
      });
    });
  },
  applyState: (instance) => {
    const applying = state2Model(instance)
    const request = new ApplyStateRequest();
    request.setState(applying)
    return new Promise((resolve) => {
      statesClient.applyState(request, getMetadata(), (err, response) => {
        if (response == null) {
          console.log(err)
        } else {
          resolve(response.toObject())
        }
      })
    })
  },
  listCities: () => {
    const request = new ListCitiesRequest();
    return new Promise((resolve) => {
      citiesClient.listCities(request, getMetadata(), (err, response) => {
        if (response == null) {
          console.log(err)
        } else {
          resolve(response.toObject().citiesList)
        }
      });
    });
  },
  getCity: (name) => {
    const request = new GetCityRequest()
    request.setName(name)
    return new Promise((resolve) => {
      citiesClient.getCity(request, getMetadata(), (err, response) => {
        if (response == null) {
          console.log(err)
        } else {
          resolve(response.toObject())
        }
      });
    });
  },
  applyCity: (instance) => {
    const applying = city2Model(instance)
    const request = new ApplyCityRequest();
    request.setCity(applying)
    return new Promise((resolve) => {
      citiesClient.applyCity(request, getMetadata(), (err, response) => {
        if (response == null) {
          console.log(err)
        } else {
          resolve(response.toObject())
        }
      })
    })
  },
  listQuarters: (params) => {
    const request = new ListQuartersRequest();
    if (params?.page) {
      request.setPage(params.page)
    }
    if (params?.perPage) {
      request.setPerPage(params.perPage)
    }
    if (params?.sort) {
      request.setSort(params.sort)
    }
    if (params?.sortColumn) {
      request.setSortColumn(params.sortColumn)
    }
    if (params?.search) {
      const wrapped = new wrappers.StringValue()
      wrapped.setValue(params.search)
      request.setSearch(wrapped)
    }
    if (params?.hasDescription !== undefined) {
      const wrapped = new wrappers.BoolValue()
      wrapped.setValue(!!params.hasDescription)
      request.setHasDescription(wrapped)
    }
    if (params?.hasProjects !== undefined) {
      const wrapped = new wrappers.BoolValue()
      wrapped.setValue(!!params.hasProjects)
      request.setHasProjects(wrapped)
    }
    return new Promise((resolve) => {
      quartersClient.listQuarters(request, getMetadata(), (err, response) => {
        if (response == null) {
          console.log(err)
        } else {
          resolve(response.toObject())
        }
      });
    });
  },
  getQuarter: (name) => {
    const request = new GetQuarterRequest()
    request.setName(name)
    return new Promise((resolve) => {
      quartersClient.getQuarter(request, getMetadata(), (err, response) => {
        if (response == null) {
          console.log(err)
        } else {
          resolve(response.toObject())
        }
      });
    });
  },
  applyQuarter: (instance) => {
    const applying = quarter2Model(instance)
    const request = new ApplyQuarterRequest();
    request.setQuarter(applying)
    return new Promise((resolve) => {
      quartersClient.applyQuarter(request, getMetadata(), (err, response) => {
        if (response == null) {
          console.log(err)
        } else {
          resolve(response.toObject())
        }
      })
    })
  },
  listSettlements: (params) => {
    const request = new ListSettlementsRequest()
    if (params?.page) {
      request.setPage(params.page)
    }
    if (params?.perPage) {
      request.setPerPage(params.perPage)
    }
    if (params?.sort) {
      request.setSort(params.sort)
    }
    if (params?.sortColumn) {
      request.setSortColumn(params.sortColumn)
    }
    if (params?.search) {
      const wrapped = new wrappers.StringValue()
      wrapped.setValue(params.search)
      request.setSearch(wrapped)
    }
    if (params?.hasDescription !== undefined) {
      const wrapped = new wrappers.BoolValue()
      wrapped.setValue(!!params.hasDescription)
      request.setHasDescription(wrapped)
    }
    if (params?.hasProjects !== undefined) {
      const wrapped = new wrappers.BoolValue()
      wrapped.setValue(!!params.hasProjects)
      request.setHasProjects(wrapped)
    }
    return new Promise((resolve) => {
      settlementsClient.listSettlements(request, getMetadata(), (err, response) => {
        if (response == null) {
          console.log(err)
        } else {
          resolve(response.toObject())
        }
      });
    });
  },
  getSettlement: (name) => {
    const request = new GetSettlementRequest()
    request.setName(name)
    return new Promise((resolve) => {
      settlementsClient.getSettlement(request, getMetadata(), (err, response) => {
        if (response == null) {
          console.log(err)
        } else {
          resolve(response.toObject())
        }
      });
    });
  },
  applySettlement: (instance) => {
    const applying = settlement2Model(instance)
    const request = new ApplySettlementRequest();
    request.setSettlement(applying)
    return new Promise((resolve) => {
      settlementsClient.applySettlement(request, getMetadata(), (err, response) => {
        if (response == null) {
          console.log(err)
        } else {
          resolve(response.toObject())
        }
      })
    })
  },
  listDistricts: () => {
    const request = new ListDistrictsRequest();
    return new Promise((resolve) => {
      districtsClient.listDistricts(request, getMetadata(), (err, response) => {
        if (response == null) {
          console.log(err)
        } else {
          resolve(response.toObject().districtsList)
        }
      });
    });
  },
  getDistrict: (name) => {
    const request = new GetDistrictRequest()
    request.setName(name)
    return new Promise((resolve) => {
      districtsClient.getDistrict(request, getMetadata(), (err, response) => {
        if (response == null) {
          console.log(err)
        } else {
          resolve(response.toObject())
        }
      });
    });
  },
  applyDistrict: (instance) => {
    const applying = district2Model(instance)
    const request = new ApplyDistrictRequest();
    request.setDistrict(applying)
    return new Promise((resolve) => {
      districtsClient.applyDistrict(request, getMetadata(), (err, response) => {
        if (response == null) {
          console.log(err)
        } else {
          resolve(response.toObject())
        }
      })
    })
  },
};

export default API;
