// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

import API from 'api/property_queries'

export const listLeads = createAsyncThunk('appLeads/listLeads', async (params, { signal }) => {
  const response = await API.listPropertyQueries(params)
  if (signal.aborted)
    return
  return {
    params,
    data: response.propertyQueriesList,
    totalRows: response.totalRows
  }
})

export const getLead = createAsyncThunk('appLeads/getLead', async (name) => {
  return await API.getPropertyQuery(name)
})

export const addLead = createAsyncThunk('appLeads/addLead', async (instance) => {
  const updated = await API.applyPropertyQuery(instance)
  instance = {
    ...instance,
    ...updated
  }
  return instance
})

export const modifyLead = createAsyncThunk('appLeads/modifyLead', async (instance, { dispatch, getState }) => {
  const updated = await API.applyPropertyQuery(instance)
  instance = {
    ...instance,
    ...updated
  }
  await dispatch(appLeads.actions.setSelected(instance))
  return instance
})

export const deleteLead = createAsyncThunk('appLeads/deleteLead', async (name) => {
  await API.deletePropertyQuery(name)
  return name
})

export const appLeads = createSlice({
  name: 'appLeads',
  initialState: {
    data: [],
    total: 1,
    params: {}
  },
  reducers: {
    setSelected: (state, { payload }) => {
      state.selected = payload
    }
  },
  extraReducers: builder => {
    builder
      .addCase(listLeads.fulfilled, (state, { payload }) => {
        state.data = payload.data
        state.params = payload.params
        state.total = payload.totalRows
      })
      .addCase(getLead.fulfilled, (state, { payload }) => {
        state.selected = payload
      })
  }
})

export default appLeads.reducer
