/**
 * @fileoverview gRPC-Web generated client stub for qowner.erp.api.v1.services
 * @enhanceable
 * @public
 */

// Code generated by protoc-gen-grpc-web. DO NOT EDIT.
// versions:
// 	protoc-gen-grpc-web v1.5.0
// 	protoc              v3.20.3
// source: projects/erp/service/api/v1/services/property_queries.proto


/* eslint-disable */
// @ts-nocheck



const grpc = {};
grpc.web = require('grpc-web');


var google_protobuf_wrappers_pb = require('google-protobuf/google/protobuf/wrappers_pb.js')

var projects_erp_service_api_v1_model_property_query_pb = require('./property_query_pb.js')
const proto = {};
proto.qowner = {};
proto.qowner.erp = {};
proto.qowner.erp.api = {};
proto.qowner.erp.api.v1 = {};
proto.qowner.erp.api.v1.services = require('./property_queries_pb.js');

/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.qowner.erp.api.v1.services.PropertyQueriesClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname.replace(/\/+$/, '');

};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.qowner.erp.api.v1.services.PropertyQueriesPromiseClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname.replace(/\/+$/, '');

};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.qowner.erp.api.v1.services.ListPropertyQueriesRequest,
 *   !proto.qowner.erp.api.v1.services.ListPropertyQueriesResponse>}
 */
const methodDescriptor_PropertyQueries_ListPropertyQueries = new grpc.web.MethodDescriptor(
  '/qowner.erp.api.v1.services.PropertyQueries/ListPropertyQueries',
  grpc.web.MethodType.UNARY,
  proto.qowner.erp.api.v1.services.ListPropertyQueriesRequest,
  proto.qowner.erp.api.v1.services.ListPropertyQueriesResponse,
  /**
   * @param {!proto.qowner.erp.api.v1.services.ListPropertyQueriesRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.qowner.erp.api.v1.services.ListPropertyQueriesResponse.deserializeBinary
);


/**
 * @param {!proto.qowner.erp.api.v1.services.ListPropertyQueriesRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.qowner.erp.api.v1.services.ListPropertyQueriesResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.qowner.erp.api.v1.services.ListPropertyQueriesResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.qowner.erp.api.v1.services.PropertyQueriesClient.prototype.listPropertyQueries =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/qowner.erp.api.v1.services.PropertyQueries/ListPropertyQueries',
      request,
      metadata || {},
      methodDescriptor_PropertyQueries_ListPropertyQueries,
      callback);
};


/**
 * @param {!proto.qowner.erp.api.v1.services.ListPropertyQueriesRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.qowner.erp.api.v1.services.ListPropertyQueriesResponse>}
 *     Promise that resolves to the response
 */
proto.qowner.erp.api.v1.services.PropertyQueriesPromiseClient.prototype.listPropertyQueries =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/qowner.erp.api.v1.services.PropertyQueries/ListPropertyQueries',
      request,
      metadata || {},
      methodDescriptor_PropertyQueries_ListPropertyQueries);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.qowner.erp.api.v1.services.GetPropertyQueryRequest,
 *   !proto.qowner.erp.api.v1.model.PropertyQuery>}
 */
const methodDescriptor_PropertyQueries_GetPropertyQuery = new grpc.web.MethodDescriptor(
  '/qowner.erp.api.v1.services.PropertyQueries/GetPropertyQuery',
  grpc.web.MethodType.UNARY,
  proto.qowner.erp.api.v1.services.GetPropertyQueryRequest,
  projects_erp_service_api_v1_model_property_query_pb.PropertyQuery,
  /**
   * @param {!proto.qowner.erp.api.v1.services.GetPropertyQueryRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  projects_erp_service_api_v1_model_property_query_pb.PropertyQuery.deserializeBinary
);


/**
 * @param {!proto.qowner.erp.api.v1.services.GetPropertyQueryRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.qowner.erp.api.v1.model.PropertyQuery)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.qowner.erp.api.v1.model.PropertyQuery>|undefined}
 *     The XHR Node Readable Stream
 */
proto.qowner.erp.api.v1.services.PropertyQueriesClient.prototype.getPropertyQuery =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/qowner.erp.api.v1.services.PropertyQueries/GetPropertyQuery',
      request,
      metadata || {},
      methodDescriptor_PropertyQueries_GetPropertyQuery,
      callback);
};


/**
 * @param {!proto.qowner.erp.api.v1.services.GetPropertyQueryRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.qowner.erp.api.v1.model.PropertyQuery>}
 *     Promise that resolves to the response
 */
proto.qowner.erp.api.v1.services.PropertyQueriesPromiseClient.prototype.getPropertyQuery =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/qowner.erp.api.v1.services.PropertyQueries/GetPropertyQuery',
      request,
      metadata || {},
      methodDescriptor_PropertyQueries_GetPropertyQuery);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.qowner.erp.api.v1.services.ApplyPropertyQueryRequest,
 *   !proto.qowner.erp.api.v1.model.PropertyQuery>}
 */
const methodDescriptor_PropertyQueries_ApplyPropertyQuery = new grpc.web.MethodDescriptor(
  '/qowner.erp.api.v1.services.PropertyQueries/ApplyPropertyQuery',
  grpc.web.MethodType.UNARY,
  proto.qowner.erp.api.v1.services.ApplyPropertyQueryRequest,
  projects_erp_service_api_v1_model_property_query_pb.PropertyQuery,
  /**
   * @param {!proto.qowner.erp.api.v1.services.ApplyPropertyQueryRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  projects_erp_service_api_v1_model_property_query_pb.PropertyQuery.deserializeBinary
);


/**
 * @param {!proto.qowner.erp.api.v1.services.ApplyPropertyQueryRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.qowner.erp.api.v1.model.PropertyQuery)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.qowner.erp.api.v1.model.PropertyQuery>|undefined}
 *     The XHR Node Readable Stream
 */
proto.qowner.erp.api.v1.services.PropertyQueriesClient.prototype.applyPropertyQuery =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/qowner.erp.api.v1.services.PropertyQueries/ApplyPropertyQuery',
      request,
      metadata || {},
      methodDescriptor_PropertyQueries_ApplyPropertyQuery,
      callback);
};


/**
 * @param {!proto.qowner.erp.api.v1.services.ApplyPropertyQueryRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.qowner.erp.api.v1.model.PropertyQuery>}
 *     Promise that resolves to the response
 */
proto.qowner.erp.api.v1.services.PropertyQueriesPromiseClient.prototype.applyPropertyQuery =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/qowner.erp.api.v1.services.PropertyQueries/ApplyPropertyQuery',
      request,
      metadata || {},
      methodDescriptor_PropertyQueries_ApplyPropertyQuery);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.qowner.erp.api.v1.services.DeletePropertyQueryRequest,
 *   !proto.qowner.erp.api.v1.services.DeletePropertyQueryResponse>}
 */
const methodDescriptor_PropertyQueries_DeletePropertyQuery = new grpc.web.MethodDescriptor(
  '/qowner.erp.api.v1.services.PropertyQueries/DeletePropertyQuery',
  grpc.web.MethodType.UNARY,
  proto.qowner.erp.api.v1.services.DeletePropertyQueryRequest,
  proto.qowner.erp.api.v1.services.DeletePropertyQueryResponse,
  /**
   * @param {!proto.qowner.erp.api.v1.services.DeletePropertyQueryRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.qowner.erp.api.v1.services.DeletePropertyQueryResponse.deserializeBinary
);


/**
 * @param {!proto.qowner.erp.api.v1.services.DeletePropertyQueryRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.qowner.erp.api.v1.services.DeletePropertyQueryResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.qowner.erp.api.v1.services.DeletePropertyQueryResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.qowner.erp.api.v1.services.PropertyQueriesClient.prototype.deletePropertyQuery =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/qowner.erp.api.v1.services.PropertyQueries/DeletePropertyQuery',
      request,
      metadata || {},
      methodDescriptor_PropertyQueries_DeletePropertyQuery,
      callback);
};


/**
 * @param {!proto.qowner.erp.api.v1.services.DeletePropertyQueryRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.qowner.erp.api.v1.services.DeletePropertyQueryResponse>}
 *     Promise that resolves to the response
 */
proto.qowner.erp.api.v1.services.PropertyQueriesPromiseClient.prototype.deletePropertyQuery =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/qowner.erp.api.v1.services.PropertyQueries/DeletePropertyQuery',
      request,
      metadata || {},
      methodDescriptor_PropertyQueries_DeletePropertyQuery);
};


module.exports = proto.qowner.erp.api.v1.services;

