export const ApiUrl = process.env.REACT_APP_API_ERP_URL ?? 'https://admin-api.qowner.com:7070'

function parseJwt(token) {
  const base64Url = token.split('.')[1];
  const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
  const jsonPayload = decodeURIComponent(window.atob(base64).split('').map(function (c) {
    return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2)
  }).join(''));

  return JSON.parse(jsonPayload)
}

export const getMetadata = () => {
  localStorage.setItem('lastApiCall', parseInt((new Date().getTime() / 1000).toFixed(0)))
  const token = localStorage.getItem('accessToken')
  if (!token) {
    return null
  }
  const jwt = parseJwt(token)
  const current_time = Date.now() / 1000;
  if ( jwt.exp < current_time) {
    localStorage.removeItem('accessToken')
    localStorage.removeItem('refreshToken')
    localStorage.removeItem('userData')
    window.location.reload()
  }
  return { authorization: 'Bearer ' + token }
}

export const getFileUrl = (url) => {
  if (!url)
    return null

  if (url.startsWith("minio://"))
    return ApiUrl + '/v1/files/-/' + url.substring("minio://".length).replace(/ /g, '%20')

  if (!url.startsWith(ApiUrl)) {
    return ApiUrl + '/v1/files?url=' + encodeURI(url)
  }

  return url.replace(/ /g, '%20')
}
